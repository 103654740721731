@import "../../global.scss";

li{
    font-size: 14px;
    margin-right: 50px;
    padding:7px;
    border-radius:10px;
    cursor:pointer;

    &.active{
        background-color: $mainColor;
        color:white;
    }

}